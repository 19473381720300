import {
  Button,
  CircularProgress,
  Dialog,
  InputAdornment,
  LinearProgress,
  Table,
  TableBody,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import Excel from "exceljs";
import { GetApp, Search } from "@material-ui/icons";
import useTable, { StyledTableCell, StyledTableRow } from "components/UseTable";
import { DialogActions, DialogContent, DialogTitle } from "Utils";
import axiosInstance from "../pages/Services/AxiosInstance";
import RenewalForm, { useStylesRenewalForm } from "pages/EmployeeRenewalForm/RenewalForm";
import moment from "moment";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { StatusChip } from "pages/AdminRenewalForm/AdminRenewalForm";
import { saveAs } from "file-saver";

const headCells = [
  { id: "S.No.", label: "S.No.", align: "left" },
  { id: "employeeName", label: "Employee Name", align: "left" },
  { id: "unitCode", label: "Unit Code", align: "left" },
  { id: "isEmpAlive", label: "Employee Alive", align: "left" },
  { id: "employeeId", label: "Employee Id", align: "left" },
  { id: "empMobileNo", label: "Mobile No.", align: "left" },
  { id: "spouseName", label: "Spouse Name", align: "left" },
  { id: "isSpouseAlive", label: "Spouse Alive", align: "left" },
  { id: "submittedDate", label: "Submitted Date", align: "left" },
  { id: "financialYear", label: "Financial Year", align: "left" },
  { id: "renewalStatus", label: "Status", align: "left" },
  { id: "action", label: "Action", align: "left", disableSorting: true },
];

// ---------------------------------------------------- PREVIEW -------------------------------------------------------------------------- //
function PreviewForm({ onClose, employeeDetails, viewMode, reset }) {
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingImgError, setLoadingImgError] = useState("");
  const [remarks, setRemarks] = useState("");
  const [oldRemarks,setOldRemarks]= useState([]);
  const [selectedRowEmpDetail, setSelectedRowEmpDetail] = useState(null);
  const [renewalStatus,setRenewalStatus] =useState("");

  async function handleApprove() {
    if (!remarks.trim()) {
      toast.error("Please enter remarks before approval");
      return;
    }

    if (employeeDetails) {
      setSubmitting(true);
      const req = {
        id: employeeDetails.id,
        renewalStatusId: 3,
        remarks: remarks,
      };
      try {
        await axiosInstance.post("renewal/updateApproval", req);
        reset();
        toast.success("Successfully approved");
        onClose();
      } catch (error) {
        toast.error("Failed to approve, please try again");
        console.log(error);
      } finally {
        setSubmitting(false);
      }
    }
  }

  async function handleReject() {
    if (!remarks.trim()) {
      toast.error("Please enter remarks before rejection");
      return;
    }
    if (employeeDetails) {
      setSubmitting(true);
      const req = {
        id: employeeDetails.id,
        renewalStatusId: 4,
        remarks: remarks,
      };
      try {
        await axiosInstance.post("renewal/updateApproval", req);
        reset();
        toast.success("Successfully Rejected");
        onClose();
      } catch (error) {
        toast.error("Failed to reject, please try again");
        console.log(error);
      } finally {
        setSubmitting(false);
      }
    }
  }

  useEffect(() => {
    if (employeeDetails) {
      setLoading(true);
      setRemarks("");
      // const newRemarks = employeeDetails?.remarks.replace(/\+/g, "||");
      // const newSplittedRemarks = employeeDetails?.remarks?.split('+')
      const newSplittedRemarks = employeeDetails?.remarks
  ? employeeDetails?.remarks.split('+')
  : [employeeDetails?.remarks];
      setOldRemarks(newSplittedRemarks);

      setRenewalStatus(employeeDetails.renewalStatusId)
      const req = { id: employeeDetails?.id };
      axiosInstance
        .post("renewal/getById", req)
        .then(({ data }) => {
          setSelectedRowEmpDetail(data);
            // Check if the response contains remarks and split them
        const newRemarks = data?.remarks
        ? data?.remarks.split('+')
        : [data?.remarks];
      setOldRemarks(newRemarks);
        })
        .catch((e) => {
          console.log(e);
          setLoadingImgError("Failed to Load Image, please reopen the dialog for approval");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [employeeDetails]);
  console.log(oldRemarks,"yhdsg");
  return (
    <Dialog open maxWidth="lg" fullWidth>
      <DialogTitle onClose={onClose}>Renewal Form</DialogTitle>
      <DialogContent dividers>
        {loading && <LinearProgress />}
        <RenewalForm isEditing selectedForm={selectedRowEmpDetail} onlyPreview />
        <div style={{ padding: 16, margin: "auto" }}>
          {/* <div style={{ textAlign: "center", marginInline: "auto" }}>
            <TextField
              multiline
              minRows={2}
              fullWidth
              variant="outlined"
              placeholder="Please enter approval or rejection remarks"
              // onChange={(e) => setRemarks(e.target.value)}
              
              value={oldRemarks}
              inputProps={{
                readOnly: employeeDetails.renewalStatusId === 3 || employeeDetails.renewalStatusId === 4 || viewMode,
              }}
            />
          </div> */}
   
            <div>

      {oldRemarks.length > 0 && oldRemarks[0] !== null && oldRemarks.map((oldremarksList, index) => (
        
        <TextField
          key={index}
          label={`Remarks ${index + 1}`} // Dynamic label name
          multiline
          fullWidth
          defaultValue={oldremarksList}
          inputProps={{
            readOnly: employeeDetails.renewalStatusId === 3 || employeeDetails.renewalStatusId === 4 ||employeeDetails.renewalStatusId === 2||employeeDetails.renewalStatusId === 1|| viewMode,
          }}
          // Add any other desired props here
        />
      ))}
    </div>
   
        </div>
        {renewalStatus !== 3 && renewalStatus !== 4 && (
        <div style={{ padding: 16, margin: "auto" }}>
          <div style={{ textAlign: "center", marginInline: "auto" }}>
            <TextField
              multiline
              minRows={2}
              fullWidth
              variant="outlined"
              placeholder="Please enter approval or rejection remarks"
              onChange={(e) => setRemarks(e.target.value)}
              value={remarks}
              inputProps={{
                readOnly: employeeDetails.renewalStatusId === 3 || viewMode,
              }}
            />
          </div>
        </div>
        )}
        <div style={{ textAlign: "center" }}>
          {loadingImgError && <Typography color="error">{loadingImgError}</Typography>}
          {!viewMode && (
            <div style={{ display: "flex", gap: 16, justifyContent: "center" }}>
              <Button
                disabled={submitting || loading || loadingImgError|| renewalStatus ===3||renewalStatus ===4}
                color="primary"
                size="small"
                onClick={handleApprove}
                variant="contained"
                style={{ minWidth: 150 }}
              >
                {submitting && <CircularProgress size={24} />} Approve
              </Button>

              <Button
                disabled={submitting || loading || loadingImgError|| renewalStatus ===3||renewalStatus ===4}
                color="secondary"
                size="small"
                onClick={handleReject}
                variant="contained"
                style={{ minWidth: 150 }}
              >
                {submitting && <CircularProgress size={24} />} Reject
              </Button>
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" size="small" onClick={onClose} variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

// ---------------------------------------------------- RENEWAL lIST -------------------------------------------------------------------------- //

export default function RenewalList() {
  const loggedInUser = useSelector((store) => store.loggedInUser);
  const classes = useStylesRenewalForm();
  const [loading, setLoading] = useState(false);
  const [renewalData, setRenewalData] = useState([]);
  const [filterFn, setFilterFn] = useState({ fn: (items) => items });
  const [selectedForm, setSelectedForm] = useState(null);
  const [openDetails, setOpenDetails] = useState(false);
  const [viewMode, setViewMode] = useState(false);

  const requestSearch = (e) => {
    let val = e.target.value;
    setFilterFn({
      fn: (records) => {
        if (!val) return records;
        return records.filter(
          (row) =>
            row.renewalStatus
              .toString()
              .toLowerCase()
              .includes(val.toLowerCase()) ||
            row.renewalYear
              ?.toString()
              .toLowerCase()
              .includes(val.toLowerCase()) ||
            row.employeeName.toLowerCase().includes(val.toLowerCase()) ||
            row.employeeId
              ?.toString()
              .toLowerCase()
              .includes(val.toLowerCase()) ||
            row.empMobileNo
              ?.toString()
              .toLowerCase()
              .includes(val.toLowerCase())
        );
      },
    });
  };
  const handleExport = async () => {
    try {
      const wb = new Excel.Workbook();
      const data = renewalData?.map((d) => [
        d.employeeId,
        d.employeeName,
        d.gender,
        "Spouse",
        d.empMobileNo,
        d.empLandlineNo,
        d.empEmail,
        d.berechsNo ? d.berechsNo.split("/")[0] : "",
        d.category,
        d.unitCode,
        d.berechsNo,
        moment(d.empdob).format("DD-MM-YYYY"),
        d.spouseName,
        d.berechSpouseId,
        moment(d.spouseDob).format("DD-MM-YYYY"),
        // d.isSpouseAlive ? "ALIVE" : "NOT ALIVE",
        d.spouseStatus,
        d.renewalStatus,
        moment(d.submittedDate).format("DD-MM-YYYY"), //Renewed Date
        moment(d.createdDatetime).format("DD-MM-YYYY"), //Start Date
        d.submittedDate, //End Date
        d.age,
        // '',//Coverage Amount ( in Rs. )
        d.address,
        d.secondAddressLine,
        d.district,
        d.postalCode,
        d.country,
        d.careOf,
        d?.addressDto?.addressLine1,
        d?.addressDto?.addressLine2,
        d?.addressDto?.pincode,
        d?.addressDto?.countryName,
        d?.addressDto?.stateName,
        d?.addressDto?.districtName,
        d.employeeName, //Name of Insured
        d.renewalYear,
        d.remarks,
      ]);
      const sheet = wb.addWorksheet("Inspection PDI of Spares");
      const tableData = data.map((r, i) => [...r]);
      const wsData = [
        [
          "Personnel Number",
          "Employee Name",
          "Emp Gender",
          "Relationship",
          "Cell No",
          "Landline",
          "Email Address",
          "Membership Number",
          "Category",
          "Unit Code",
          "Membership ID",
          "Date of Birth",
          "Spouse Name",
          "Spouse Membership ID",
          "Spouse DOB",
          "Spouse Status",
          "Renewal Status",
          "Renewed Date",
          "Start Date",
          "End Date",
          "Age(YY.MM) as on Start Dt.",
          // "Coverage Amount ( in Rs. )",
          "Address Line1",
          "Second Address Line",
          "District",
          "PostalCode",
          "Country",
          "CareOf",
          "Address Line1",
          "Address Line2",
          "Pin Code",
          "Country Name",
          "State Name",
          "District Name",
          "Employee Name",
          "Financial Year",
          "Remarks",
        ],
        ...tableData,
      ];
      sheet.columns = [{ width: 30 }, ...Array(3).fill({ width: 30 })];

      sheet.addRows(wsData);

      const range = (start, end) => Array.from({ length: end }, (_, i) => i + start);

      range(1, 18).forEach(
        (c) =>
          (sheet.getColumn(c).alignment = {
            vertical: "middle",
            horizontal: "left",
          })
      );
      [
        "A1",
        "B1",
        "C1",
        "D1",
        "E1",
        "F1",
        "G1",
        "H1",
        "I1",
        "J1",
        "K1",
        "L1",
        "M1",
        "N1",
        "O1",
        "P1",
        "Q1",
        "R1",
        "S1",
        "T1",
        "U1",
        "V1",
        "W1",
        "X1",
        "Y1",
        "Z1",
        "AA1",
        "AB1",
        "AC1",
        "AD1",
        "AE1",
        "AF1",
        "AG1",
        "AH1",
        "AI1",
        "AJ1",
        "AK1",
      ].forEach((e) => (sheet.getCell(e).font = { bold: true }));

      wb.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, `Renewal-Application-list.xls`);
      });
    } catch (error) {
      toast.error("Failed to download");
    }
  };
  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } = useTable(renewalData, headCells, filterFn, 15);

  function getRenewalData() {
    const unitCodeValue = loggedInUser.roleId === 7 ? "" : loggedInUser?.unitCode;
    setLoading(true);
    axiosInstance(`renewal/getAllSubmittedRene?unitCode=${unitCodeValue}`)
      .then(({ data }) => {
        if (Array.isArray(data)) {
          setRenewalData(
            data.map((e) => ({
              ...e,
              renewalStatus:
                e.renewalStatus === "Sent" || e.renewalStatus === "Draft"
                  ? "Pending"
                  : e.renewalStatus === "Approved"
                  ? "Approved"
                  : "Rejected",
              submittedDate: e.submittedDate ? moment(e.submittedDate).format("DD-MM-YYYY") : "NA",
              // renewalYear: e.renewalYear ? `${e.renewalYear} - ${(parseInt(e.renewalYear, 10) + 1)}` : null,
              renewalYear: e.renewalYear ? `${e.renewalYear} - ${(parseInt(e.renewalYear, 10) + 1) % 100}` : null,
            }))
          );
        }
      })
      .catch(console.log)
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    getRenewalData();
  }, [loggedInUser]);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "end", alignItems: "center", gap: "5px" }}>
        <TextField
          placeholder="Search..."
          variant="outlined"
          size="small"
          className={classes.searchInput}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          onChange={requestSearch}
        />
        <Button onClick={handleExport} endIcon={<GetApp />} variant="outlined" color="secondary" style={{ marginLeft: "10px" }}>
          Export Data
        </Button>
      </div>
      <TblContainer style={{ height: "600px" }}>
        {loading && <LinearProgress />}
        <Table
          style={{
            position: "relative",
            border: "1px solid lightgrey",
          }}
          size="small"
        >
          <TblHead />
          {recordsAfterPagingAndSorting()?.length !== 0 ? (
            <TableBody>
              {recordsAfterPagingAndSorting()?.map((item, index) => (
                <TableRow key={item.id} className={classes.tableColor}>
                  <StyledTableCell>{index + 1} </StyledTableCell>
                  <StyledTableCell>{item.employeeName} </StyledTableCell>
                  <StyledTableCell>{item.unitCode} </StyledTableCell>
                  <StyledTableCell>{item.isEmpAlive ? "Yes" : "No"} </StyledTableCell>
                  <StyledTableCell>{item.employeeId} </StyledTableCell>
                  <StyledTableCell>{item.empMobileNo} </StyledTableCell>
                  <StyledTableCell>{item.spouseName} </StyledTableCell>
                  {/* <StyledTableCell>{item.isSpouseAlive ? "Yes" : "No"} </StyledTableCell> */}
                  <StyledTableCell>{item.spouseStatus} </StyledTableCell>
                  <StyledTableCell>{item.submittedDate} </StyledTableCell>
                  <StyledTableCell>{item.renewalYear} </StyledTableCell>
                  <StyledTableCell>
                    <StatusChip status={item.renewalStatus} />
                  </StyledTableCell>
                  <StyledTableCell>
                    {item.renewalStatus === "Approved" || item.renewalStatus === "Rejected" || loggedInUser.roleId === 7 ? (
                      <Button
                        onClick={() => {
                          setOpenDetails(true);
                          setSelectedForm(item);
                          setViewMode(true);
                        }}
                        style={{ minWidth: 100 }}
                        color="primary"
                        size="small"
                        variant="outlined"
                      >
                        View
                      </Button>
                    ) : (
                      loggedInUser.roleId !== 7 && (
                        <Button
                          onClick={() => {
                            setOpenDetails(true);
                            setSelectedForm(item);
                            setViewMode(false);
                          }}
                          style={{ minWidth: 100 }}
                          variant="outlined"
                          color="primary"
                          size="small"
                        >
                          Action
                        </Button>
                      )
                    )}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <StyledTableRow>
                <StyledTableCell colSpan={8} align="center">
                  No records found
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          )}
        </Table>
        {recordsAfterPagingAndSorting()?.length ? <TblPagination /> : null}
      </TblContainer>
      {openDetails && (
        <PreviewForm
          onClose={() => {
            setOpenDetails(false);
            setViewMode(null);
          }}
          reset={getRenewalData}
          employeeDetails={selectedForm}
          viewMode={viewMode}
        />
      )}
    </>
  );
}
