import {
  Avatar,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import { useCallback, useEffect, useRef, useState } from "react";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { Attachment, Camera, InsertPhoto, PhotoCamera, Done, Close, ExpandMore, ExpandLess } from "@material-ui/icons";
import axiosInstance from "../Services/AxiosInstance";
import moment from "moment";
import { DialogActions, DialogContent, DialogTitle } from "Utils";
import { useSelector } from "react-redux";
import { restrict } from "restrict";
import { Required } from "NewLayout/LoginPage";
import { emailRegex, mobileReg } from "NewLayout/ProfilePage";
import { select } from "@syncfusion/ej2-base";

export const useStylesRenewalForm = makeStyles((theme) => ({
  input: {
    color: "blue", // Change this to your desired color
    fontSize: 16,
  },
  inputRoot: {
    maxWidth: "300px",
    width: "100%",
  },
  buttonRoot: {
    textTransform: "initial",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  paper: {
    overflow: "hidden",
    height: "150px",
    maxWidth: 250,
    paddingInline: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  orderedList: {
    counterReset: "item",
    paddingLeft: 0,
  },
  listItem: {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: theme.spacing(0.5),
    "&::before": {
      content: 'counter(item) "."',
      counterIncrement: "item",
      minWidth: "1em", // Adjust this value to increase/decrease spacing
      marginRight: theme.spacing(1), // Adjust this value to increase/decrease spacing
      marginTop: "2px", // Adjust this value to increase/decrease spacing
      textAlign: "right",
    },
  },
  tableDesign: {
    marginTop: "10px",
    width: "100%",
    verticalAlign: "middle",
    // border: "1px solid grey",
    borderCollapse: "collapse",
    "& tbody tr td": {
      border: "1px solid grey",
      padding: "5px 10px",
      fontSize: "16px",
      textAlign: "left",
    },
    "& tbody tr td:nth-child(1)": {
      backgroundColor: "#f0f8ff",
      fontWeight: "500",
    },
  },
  formControlLabel: {
    color: "red",
  },
}));

const today = new Date();

const getMonth = today.getMonth();
const getYear = getMonth <= 3 ? today.getFullYear() : today.getFullYear() + 1;

const defaultValues = {
  address: "",
  organization: "",
  // retirementDate: "",
  retireeMobile: "",
  retireeLandline: "",
  retireeEMailId: "",
  spouseMobile: "",
  spouseLandline: "",
  spouseEMailId: "",
  isSpouseWorking: "",
  isSpouseMember: "",
  attachNoc: "",
  isUtilizedScheme: false,
  isRenewMembership: false,
  deathDeclaration: null,
  deathDate: "",
  isBerechMember: null,
  isEmpAlive: "",
  isSpouseAlive: "",
  spouseStatus: "",
  districtId: "",
  stateId: "",
  countryId: "",
  empTelecodeId: "1",
  spouseTelecodeId: "1",
  empLandlineTelecodeId: "1",
  spouseLandlineTelecodeId: "1",
  addressLine1: "",
  addressLine2: "",
};

const schema = yup.object().shape({
  isEmpAlive: yup
    .boolean()
    .nullable()
    .required("Required")
    .typeError("Required"),
  isSpouseAlive: yup
    .boolean()
    .nullable()
    .required("Required")
    .typeError("Required"),
  // address: yup.string().required("Required"),
  organization: yup.string().when("isSpouseWorking", (isSpouseWorking) => {
    if (isSpouseWorking === "yes") {
      return yup
        .string()
        .trim()
        .required("Required");
    }
  }),
  // retirementDate: yup
  //   .date()
  //   .typeError("Required")
  //   .required("Required"),
  deathDate: yup
    .date()
    // .typeError("Required")
    .nullable()
    .notRequired()
    .when(["isSpouseAlive", "isEmpAlive", "spouseStatus"], (isSpouseAlive, isEmpAlive, spouseStatus) => {
      if ((!isSpouseAlive && spouseStatus?.toUpperCase() === "EXPIRED") || !isEmpAlive) {
        return yup
          .date()
          .typeError("Required")
          .required("Required");
      }
    }),
  retireeMobile: yup
    .string()
    .typeError("Required")
    .matches(mobileReg, "Invalid Mobile No.")
    .required("Required.."),
  retireeLandline: yup
    .string()
    .nullable()
    .when((value) => {
      if (value) {
        return yup
          .string()
          .nullable()
          .matches(mobileReg, "Invalid Mobile No.");
      }
    }),
  retireeEMailId: yup
    .string()
    .email("Email must be valid")
    .nullable()
    .required("Required")
    .matches(emailRegex, "Email must be valid"),
  spouseMobile: yup.string().when("isSpouseAlive", (isSpouseAlive) => {
    if (isSpouseAlive) {
      return yup
        .string()
        .required("Required")
        .nullable()
        .matches(mobileReg, "Invalid Mobile No.");
    }
  }),
  spouseLandline: yup.string().when((value) => {
    if (value) {
      return yup
        .string()
        .nullable()
        .matches(mobileReg, "Invalid Mobile No.");
    }
  }),
  spouseEMailId: yup
    .string()
    .trim()
    .notRequired()
    .when((value) => {
      if (value) {
        return yup
          .string()
          .email("Email must be valid")
          .matches(emailRegex, "Invalid Email");
      }
    }),
  isSpouseMember: yup
    .string()
    .nullable()
    .when("isSpouseWorking", (isSpouseWorking) => {
      if (isSpouseWorking === "yes") {
        return yup
          .string()
          .nullable()
          .required("Required");
      }
    }),
  isSpouseWorking: yup
    .string()
    .nullable()
    .when("isSpouseAlive", (isSpouseAlive) => {
      if (isSpouseAlive) {
        return yup
          .string()
          .nullable()
          .required("Required");
      }
    }),
  isRenewMembership: yup.boolean().nullable(),
  isUtilizedScheme: yup.boolean().nullable(),
  isBerechMember: yup
    .boolean()
    .nullable()
    .required("Required")
    .oneOf([true], "Required"),
  districtId: yup
    .string()
    .trim()
    .nullable(),
  stateId: yup
    .string()
    .trim()
    .nullable(),
  countryId: yup
    .string()
    .trim()
    .nullable(),
  addressLine1: yup
    .string()
    .trim()
    .nullable(),
  pincode: yup
    .string()
    .notRequired()
    .when((value) => {
      if (value) {
        return yup
          .string()
          .min(6, "Minimum should be 6 digit")
          .max(8, "Maximum should be 8 digit");
      }
    }),
  empTelecodeId: yup
    .string()
    .trim()
    .nullable(),
  spouseTelecodeId: yup
    .string()
    .trim()
    .nullable(),
  empLandlineTelecodeId: yup
    .string()
    .trim()
    .nullable(),
  spouseLandlineTelecodeId: yup
    .string()
    .trim()
    .nullable(),
});
const validFileTypes = ["pdf", "jpg", "png", "jpeg"];
export default function RenewalForm({ onlyPreview, selectedForm, refreshData, isEditing }) {
  const loggedInUser = useSelector((store) => store.loggedInUser);
  const theme = useTheme();
  const nocFileRef = useRef(null);
  const deathCertificateRef = useRef(null);
  const classes = useStylesRenewalForm();
  const [capturedImageRetiree, setCapturedImageRetiree] = useState(null);
  const [capturedImageSpouse, setCapturedImageSpouse] = useState(null);

  const [retirePassportPhotoStr, setRetirePassportPhotoStr] = useState(null);
  const [spousePassportPhotoStr, setSpousePassportPhotoStr] = useState(null);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [employeeDetails, setEmployeeDetails] = useState({});
  // const [renewalData, setRenewalData] = useState([]);
  // const [filterFn, setFilterFn] = useState({ fn: (items) => items });
  // const [selectedForm, setSelectedForm] = useState(null);
  // const [openDetails, setOpenDetails] = useState(false);

  const [viewNocDoc, setViewNocDoc] = useState(false);
  const [isOpenInstruction, setIsOpenInstruction] = useState(false);
  const [nocFile, setNocFile] = useState(null);
  const [deathCertificateFile, setDeathCertificateFile] = useState(null);
  const [previewDocFile, setPreviewDocFile] = useState(null);
  const [isAlive, setIsAlive] = useState({ emp: "", spouse: "" });
  const [isSubmitted, setIsSubmitted] = useState("");
  const [renewalStatus, setRenewalStatus] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const [disableDraftButton, setDisableDraftButton] = useState(false);

  const {
    handleSubmit,
    control,
    getValues,
    reset,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const getLoggedInRetireeDetail = () => {
    setLoading(true);
    axiosInstance
      .get("employee/getSavedEmpDetails")
      .then(({ data }) => {
        setEmployeeDetails(data);
        setIsAlive({
          // emp: data?.isEmpAlive === true ? "yes" : data?.isEmpAlive === false ? "no" : "",
          emp:
            data?.empStatus?.toUpperCase() === "ALIVE"
              ? "yes"
              : data?.empStatus?.toUpperCase() === "ABEYANCE"
              ? "no"
              : data?.empStatus?.toUpperCase() === "EXPIRED"
              ? "no"
              : data?.empStatus?.toUpperCase() === "WITHDRAWN"
              ? "no"
              : "",
          // spouse: data?.isSpouseAlive === true ? "yes" : data?.isSpouseAlive === false ? "no" : "",
          spouse:
            data?.spouseStatus?.toUpperCase() === "ALIVE"
              ? "yes"
              : data?.spouseStatus?.toUpperCase() === "ABEYANCE"
              ? "no"
              : data?.spouseStatus?.toUpperCase() === "EXPIRED"
              ? "no"
              : data?.spouseStatus?.toUpperCase() === "WITHDRAWN"
              ? "no"
              : data?.spouseStatus?.toUpperCase() === "NA"
              ? "na"
              : "",
        });
        setIsSubmitted(data.submittedDate);
        setDisableButton(data.statusId === 1 || data.statusId === 3);
        setDisableDraftButton(data.statusId === 1 || data.statusId === 3);
        setRenewalStatus(data.statusId);
        reset({
          ...data,
          retireeMobile: data.empMobileNo,
          retireeLandline: data.empLandlineNo,
          retireeEMailId: data.email,
          spouseEMailId: data.spouseEmail ?? "",
          spouseMobile: data.spouseMobileNo ?? "",
          spouseLandline: data.spouseLandlineNo ?? "",
          address: data.address ?? "",
          organization: data.spouseDept ?? "",
          retirementDate: data.spouseRetirementDate ? moment(data.spouseRetirementDate).format("YYYY-MM-DD") : "",
          isSpouseMember: data.isSpouseMember === true ? "yes" : data.isSpouseMember === false ? "no" : "",
          isSpouseWorking: data.isSpouseWorking === true ? "yes" : data.isSpouseWorking === false ? "no" : "",
          addressLine1: data?.addressDto?.addressLine1 ?? "",
          addressLine2: data?.addressDto?.addressLine2 ?? "",
          pincode: data?.addressDto?.pincode ?? "",
          countryId: data?.addressDto?.countryId ?? "",
          stateId: data?.addressDto?.stateId ?? "",
          districtId: data?.addressDto?.districtId ?? "",
          empTelecodeId: data?.empTelecodeId ? data?.empTelecodeId : "1",
          spouseTelecodeId: data?.spouseTelecodeId ? data?.spouseTelecodeId : "1",
          empLandlineTelecodeId: data?.empLandlineTelecodeId ? data?.empLandlineTelecodeId : "1",
          spouseLandlineTelecodeId: data?.spouseLandlineTelecodeId ? data?.spouseLandlineTelecodeId : "1",
        });
        setCapturedImageRetiree(data?.empPhotoStr ?? null);
        setCapturedImageSpouse(data?.spousePhotoStr ?? null);
        setSpousePassportPhotoStr(data?.spousePassportPhotoStr || null);
        setRetirePassportPhotoStr(data?.retirePassportPhotoStr || null);
        setNocFile(data?.nocFilenameStr ?? null);
        setDeathCertificateFile(data?.deathCertificateStr || null);
      })
      .catch(console.log)
      .finally(() => {
        setLoading(false);
      });
  };

  async function handleFormSubmit(data, type) {
    if (data.isEmpAlive === false && data.isSpouseAlive === false) {
      toast.info("At least one profile should be uploaded before submit");
      return;
    }
    if ((data.isEmpAlive && !retirePassportPhotoStr) || (data.isSpouseAlive && !spousePassportPhotoStr)) {
      toast.info("Please upload passport size images");
      return;
    }
    if (data.isEmpAlive && !capturedImageRetiree) {
      toast.info("Please upload  employee images");
      return;
    }
    if (data.isSpouseAlive && !capturedImageSpouse) {
      toast.info("Please upload  spouse images");
      return;
    }
    if (data.isSpouseAlive && data.isSpouseWorking === "yes" && data.isSpouseMember === "yes" && !nocFile) {
      toast.info("Please Attach NOC File.");
      return;
    }
    if ((isAlive.emp === "no" || (isAlive.spouse === "no" && data.spouseStatus === "EXPIRED")) && !deathCertificateFile) {
      toast.info("Please attach death certificate");
      return;
    }
    const formattedDeathDate = data.deathDate ? moment(data.deathDate).format("YYYY-MM-DD") : null;
    setSaving(true);
    try {
      const req = {
        ...selectedForm,
        ...data,
        deathDate: formattedDeathDate,
        isUtilizedScheme: data.isUtilizedScheme,
        isRenewMembership: data.isRenewMembership,
        spouseEMailId: undefined,
        retireeEMailId: undefined,
        id: selectedForm?.id || employeeDetails?.renewalId,
        employeeId: loggedInUser?.empId,
        address: data.address,
        empMobileNo: data.retireeMobile,
        empLandlineNo: data.retireeLandline,
        empEmail: data.retireeEMailId,
        spouseMobileNo: data.spouseMobile,
        spouseLandlineNo: data.spouseLandline,
        spouseEmail: data.spouseEMailId,
        spouseDept: data.organization,
        empPhotoStr: isAlive.emp ? capturedImageRetiree : undefined,
        retirePassportPhotoStr: isAlive.emp ? retirePassportPhotoStr : undefined,
        spousePhotoStr: isAlive.spouse ? capturedImageSpouse : undefined,
        spousePassportPhotoStr: isAlive.spouse ? spousePassportPhotoStr : undefined,
        renewalYear: getYear,
        isSubmit: type === "draft" ? false : true,
        renewalStatusId: type === "draft" ? 2 : 1,
        statusId: type === "draft" ? 2 : 1,
        nocFilenameStr: nocFile,
        isSpouseMember: data.isSpouseMember === "yes" ? true : data.isSpouseMember === "no" ? false : "",
        isSpouseWorking: data.isSpouseWorking === "yes" ? true : data.isSpouseWorking === "no" ? false : "",
        deathCertificateStr: deathCertificateFile,
        addressDto: {
          addressLine1: data.addressLine1,
          addressLine2: data.addressLine2,
          pincode: data.pincode,
          countryId: data.countryId || null,
          stateId: data.stateId || null,
          districtId: data.districtId || null,
        },
      };
      await axiosInstance.post("renewal/save", req);
      // console.log(req,"ddsd");
      toast.success("Form submitted successfully");
      refreshData();
      if (type === "draft") {
        setDisableDraftButton(false);
      } else {
        setDisableButton(true);
      }
      // getLoggedInRetireeDetail();
    } catch (error) {
      toast.error(error?.response?.data || "Failed to save, please try again");
    }
    setSaving(false);
  }

  async function handleSaveAsDraft() {
    const data = getValues();
    // Destructure data, excluding submittedDate
    const { submittedDate, ...otherData } = data;
    setSaving(true);
    try {
      const req = {
        ...selectedForm,
        ...otherData,
        spouseEMailId: undefined,
        retireeEMailId: undefined,
        id: selectedForm?.id || employeeDetails?.renewalId,
        employeeId: loggedInUser.empId,
        address: data.address,
        empMobileNo: data.retireeMobile,
        empLandlineNo: data.retireeLandline,
        empEmail: data.retireeEMailId,
        spouseMobileNo: data.spouseMobile,
        spouseEmail: data.spouseEMailId,
        spouseLandlineNo: data.spouseLandline,
        spouseDept: data.organization,
        spouseRetirementDate: data.retirementDate,

        empPhotoStr: isAlive.emp ? capturedImageRetiree : undefined,
        retirePassportPhotoStr: isAlive.emp ? retirePassportPhotoStr : undefined,

        spousePhotoStr: isAlive.spouse ? capturedImageSpouse : undefined,
        spousePassportPhotoStr: isAlive.spouse ? spousePassportPhotoStr : undefined,

        isSubmit: false,
        renewalYear: getYear,
        renewalStatusId: 2,
        nocFilenameStr: nocFile,
        isSpouseMember: data.isSpouseMember === "yes" ? true : data.isSpouseMember === "no" ? false : "",
        isSpouseWorking: data.isSpouseWorking === "yes" ? true : data.isSpouseWorking === "no" ? false : "",
        deathCertificateStr: deathCertificateFile,
        addressDto: {
          addressLine1: data.addressLine1,
          addressLine2: data.addressLine2,
          pincode: data.pincode,
          countryId: data.countryId,
          stateId: data.stateId,
          districtId: data.districtId,
        },
      };

      if (
        !data.address &&
        !data.retireeMobile &&
        !data.retireeLandline &&
        !data.retireeEMailId &&
        !data.spouseMobile &&
        !data.spouseEMailId &&
        !data.spouseLandline &&
        !data.organization &&
        !data.retirementDate
      ) {
        toast.error("At least one field must be filled before saving");
        setSaving(false);
        return;
      }
      console.log(req, "req");
      await axiosInstance.post("renewal/save", req);
      toast.success("Form saved successfully");
      refreshData();
      getLoggedInRetireeDetail();
    } catch (error) {
      toast.error(error?.response?.data || "Failed to save, please try again");
    }
    setSaving(false);
  }
  const handleNOCUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const maxSize = 10 * 1024 * 1024;
      if (file.size > maxSize) {
        toast.error("File is too large. Please upload an image smaller than 10MB.");
        return;
      }
      const checkIsValidFile = file.name.split(".").pop();
      const reader = new FileReader();
      reader.onloadend = () => {
        if (validFileTypes.includes(checkIsValidFile.toLowerCase())) {
          setNocFile(reader.result);
        } else {
          toast.error("Only pdf,jpg,png,jpeg file type is allowed");
        }
      };
      reader.readAsDataURL(file);
    }
  };
  const handleUploadDeathCertificate = (event) => {
    const file = event.target.files[0];
    if (file) {
      const maxSize = 10 * 1024 * 1024;
      if (file.size > maxSize) {
        toast.error("File is too large. Please upload an image smaller than 10MB.");
        return;
      }
      const checkIsValidFile = file.name.split(".").pop();
      const reader = new FileReader();
      reader.onloadend = () => {
        if (validFileTypes.includes(checkIsValidFile.toLowerCase())) {
          setDeathCertificateFile(reader.result);
        } else {
          toast.error("Only pdf, jpg,png, jpeg, file type is allowed");
        }
      };
      reader.readAsDataURL(file);
    }
  };
  function handleReset() {
    reset(defaultValues);
    setCapturedImageRetiree(null);
    setCapturedImageSpouse(null);
    setRetirePassportPhotoStr(null);
    setSpousePassportPhotoStr(null);
    setNocFile(null);
    setDeathCertificateFile(null);
    setIsAlive({ emp: "", spouse: "" });
  }
  const handleIsAlive = (type, value) => {
    setIsAlive((ps) => ({ ...ps, [type]: value }));
  };

  useEffect(() => {
    if (!isEditing) {
      getLoggedInRetireeDetail();
    }
  }, [loggedInUser]);

  useEffect(() => {
    if (isEditing) {
      setEmployeeDetails(selectedForm);
      setIsAlive({
        emp: selectedForm?.isEmpAlive === true ? "yes" : selectedForm?.isEmpAlive === false ? "no" : "",
        // spouse: selectedForm?.isSpouseAlive === true ? "yes" : selectedForm?.isSpouseAlive === false ? "no" : "",
        spouse:
          selectedForm?.spouseStatus?.toUpperCase() === "ALIVE"
            ? "yes"
            : selectedForm?.spouseStatus?.toUpperCase() === "ABEYANCE"
            ? "no"
            : selectedForm?.spouseStatus?.toUpperCase() === "EXPIRED"
            ? "no"
            : selectedForm?.spouseStatus?.toUpperCase() === "WITHDRAWN"
            ? "no"
            : selectedForm?.spouseStatus?.toUpperCase() === "NA"
            ? "na"
            : "",
      });
      reset({
        ...selectedForm,
        retireeMobile: selectedForm?.empMobileNo,
        retireeLandline: selectedForm?.empLandlineNo,
        retireeEMailId: selectedForm?.email,
        spouseEMailId: selectedForm?.spouseEmail ?? "",
        spouseMobile: selectedForm?.spouseMobileNo ?? "",
        spouseLandline: selectedForm?.spouseLandlineNo ?? "",
        address: selectedForm?.address ?? "",
        organization: selectedForm?.spouseDept ?? "",
        retirementDate: selectedForm?.spouseRetirementDate ? moment(selectedForm?.spouseRetirementDate).format("YYYY-MM-DD") : "",
        isSpouseMember: selectedForm?.isSpouseMember === true ? "yes" : selectedForm?.isSpouseMember === false ? "no" : "",
        isSpouseWorking: selectedForm?.isSpouseWorking === true ? "yes" : selectedForm?.isSpouseWorking === false ? "no" : "",
        // Address and mobile number data mappings
        addressLine1: selectedForm?.addressDto?.addressLine1 ?? "",
        addressLine2: selectedForm?.addressDto?.addressLine2 ?? "",
        pincode: selectedForm?.addressDto?.pincode ?? "",
        countryId: selectedForm?.addressDto?.countryId ?? "",
        stateId: selectedForm?.addressDto?.stateId ?? "",
        districtId: selectedForm?.addressDto?.districtId ?? "",
        empTelecodeId: selectedForm?.empTelecodeId ? selectedForm?.empTelecodeId : "1",
        spouseTelecodeId: selectedForm?.spouseTelecodeId ? selectedForm?.spouseTelecodeId : "1",
        empLandlineTelecodeId: selectedForm?.empLandlineTelecodeId ? selectedForm?.empLandlineTelecodeId : "1",
        spouseLandlineTelecodeId: selectedForm?.spouseLandlineTelecodeId ? selectedForm?.spouseLandlineTelecodeId : "1",
      });

      setNocFile(selectedForm?.nocFilenameStr ?? null);
      setCapturedImageRetiree(selectedForm?.empPhotoStr || null);
      setCapturedImageSpouse(selectedForm?.spousePhotoStr || null);

      setSpousePassportPhotoStr(selectedForm?.spousePassportPhotoStr || null);
      setRetirePassportPhotoStr(selectedForm?.retirePassportPhotoStr || null);
      setDeathCertificateFile(selectedForm?.deathCertificateStr || null);
    }
  }, [isEditing, selectedForm]);

  const watchCountry = watch("countryId");
  const watchState = watch("stateId");

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);

  // Fetch countries on component mount
  useEffect(() => {
    axiosInstance.get("country/getAll").then(({ data }) => {
      setCountries(data);
    });
  }, []);

  // Fetch states when a country is selected
  useEffect(() => {
    if (watchCountry) {
      const req = {
        countryId: watchCountry,
      };
      axiosInstance
        .post(`state/getByCountryId`, req)
        .then(({ data }) => {
          if (Array.isArray(data)) {
            setStates(data); // Assume response is a list of state names or objects
            // setDistricts([]);
          }
        })
        .catch((error) => {
          console.error("Error fetching states:", error);
          setStates([]);
        });
    }
  }, [watchCountry]);

  // Fetch districts when a state is selected
  useEffect(() => {
    if (watchState) {
      const req = {
        stateId: watchState,
      };
      axiosInstance
        .post(`district/getByStateId`, req)
        .then(({ data }) => {
          if (Array.isArray(data)) {
            setDistricts(data);
          }
        })
        .catch((error) => {
          console.error("Error fetching districts:", error);
          setDistricts([]);
        });
    }
  }, [watchState]);

  console.log(errors, "errors");

  return (
    <>
      {viewNocDoc && (
        <PreViewNocDoc
          data={previewDocFile}
          onClose={() => {
            setViewNocDoc(false);
            setPreviewDocFile(null);
          }}
        />
      )}
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div style={{ padding: 16, margin: "auto" }}>
          <Typography variant="h6" align="center" style={{ textDecoration: "underline" }}>
            RENEWAL FORM FOR BERECH SCHEME (as amended)
          </Typography>
          <div className="no-print" style={{ textAlign: "center" }}>
            <Button
              variant="text"
              endIcon={isOpenInstruction ? <ExpandLess /> : <ExpandMore fontSize="small" />}
              color="primary"
              onClick={() => {
                setIsOpenInstruction((ps) => !ps);
              }}
            >
              CLICK TO {isOpenInstruction ? "HIDE" : "VIEW"} INSTRUCTIONS
            </Button>
          </div>
          {isOpenInstruction && (
            <>
              <br />
              <Typography variant="subtitle1" align="center" style={{ textDecoration: "underline", fontWeight: "bold" }}>
                All BERECHS Members to take note of the following instructions with regard to RENEWAL of Membership
              </Typography>
              <ol className={classes.orderedList}>
                <li className={classes.listItem}>
                  <Typography variant="body1" align="justify">
                    BERECHS members are required to submit declaration by{" "}
                    <b style={{ backgroundColor: "yellow", textDecoration: "underline", textDecorationColor: "red" }}>
                      31 <sup>st</sup> December
                    </b>{" "}
                    every year to renew and continue the membership with Insurance Company.
                  </Typography>
                </li>
                <li className={classes.listItem}>
                  <Typography variant="body1" align="justify">
                    It is mandatory that both the retired employee and his/her spouse should capture live photo in the declaration.
                  </Typography>
                </li>
                <li className={classes.listItem}>
                  <Typography variant="body1" align="justify">
                    If the spouse of the retiree is employed and he/she is BERECHS member, it is mandatory to upload No Objection
                    Certificate (NOC) every year in the declaration till his/her retirement.
                  </Typography>
                </li>

                <li className={classes.listItem}>
                  <Typography variant="body1" align="justify">
                    If the declaration is not received by{" "}
                    <b style={{ backgroundColor: "yellow", textDecoration: "underline", textDecorationColor: "red" }}>
                      31 <sup>st</sup> December
                    </b>{" "}
                    of that year, membership will not be renewed and the retiree / spouse will not be eligible for any medical facility
                    under BERECH Scheme.
                  </Typography>
                </li>
              </ol>
            </>
          )}
          <Divider className="no-print" style={{ marginTop: "10px" }} />

          <br />
          <Typography variant="h6" align="center" style={{ textDecoration: "underline" }}>
            Declaration
          </Typography>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 24,
              flexWrap: "wrap",
              gap: "30px",
              alignItems: "flex-start", // Ensure the items align to the top
              minHeight: "150px", // Set a fixed minimum height to prevent shifting
            }}
          >
            <div style={{ flex: 1 }}>
              {/* Employee Alive Section */}
              Employee Alive :<Required />
              &nbsp;
              <Controller
                control={control}
                name="isEmpAlive"
                render={({ fieldState: { error }, field }) => (
                  <>
                    <Chip
                      clickable
                      avatar={
                        <Avatar>
                          <Done fontSize="small" />
                        </Avatar>
                      }
                      label="Yes"
                      onClick={() => {
                        handleIsAlive("emp", "yes");
                        setValue("isEmpAlive", true);
                        setValue("empStatus", "ALIVE");
                        clearErrors("isEmpAlive");
                      }}
                      style={{
                        minWidth: "60px",
                        backgroundColor: isAlive.emp === "yes" ? theme.palette.success.dark : theme.palette.grey[300],
                        color: isAlive.emp === "yes" ? "white" : "inherit",
                        marginRight: "4px",
                      }}
                      disabled={onlyPreview}
                    />
                    <Chip
                      clickable
                      avatar={
                        <Avatar>
                          <Close fontSize="small" />
                        </Avatar>
                      }
                      label="No"
                      onClick={() => {
                        handleIsAlive("emp", "no");
                        setValue("isEmpAlive", false);
                        setValue("empStatus", "EXPIRED");
                        clearErrors("isEmpAlive");
                      }}
                      style={{
                        minWidth: "60px",
                        backgroundColor: isAlive.emp === "no" ? theme.palette.error.dark : theme.palette.grey[300],
                        color: isAlive.emp === "no" ? "white" : "inherit",
                      }}
                      disabled={onlyPreview}
                    />
                    {!!error && <FormHelperText error>{error?.message}</FormHelperText>}
                  </>
                )}
              />
              {isAlive.emp === "yes" && (
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                    justifyContent: "center",
                    marginTop: "10px", // Add margin to create space
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      border: "1px solid lightgrey",
                      marginTop: "8px",
                      minWidth: "250px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="caption" align="center" color="secondary">
                      Capture Live Photo
                    </Typography>
                    <WebcamCapture
                      capturedImage={capturedImageRetiree}
                      setCapturedImage={setCapturedImageRetiree}
                      onlyPreview={onlyPreview}
                      disableBrowse
                      isValid={isValid}
                      setIsValid={setIsValid}
                    />
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      border: "1px solid lightgrey",
                      marginTop: "8px",
                      minWidth: "250px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="caption" align="center" color="secondary">
                      Upload Passport Size Photo
                    </Typography>
                    <WebcamCapture
                      capturedImage={retirePassportPhotoStr}
                      setCapturedImage={setRetirePassportPhotoStr}
                      onlyPreview={onlyPreview}
                      disableLiveCapture
                      isValid={isValid}
                      setIsValid={setIsValid}
                    />
                  </div>
                </div>
              )}
            </div>

            <div style={{ flex: 1 }}>
              {/* Spouse Alive Section */}
              Spouse Alive :<Required />
              &nbsp;
              <Controller
                control={control}
                name="spouseStatus"
                render={({ fieldState: { error }, field }) => (
                  <>
                    <Chip
                      clickable
                      avatar={
                        <Avatar>
                          <Done fontSize="small" />
                        </Avatar>
                      }
                      disabled={onlyPreview}
                      label="Yes"
                      onClick={() => {
                        handleIsAlive("spouse", "yes");
                        setValue("isSpouseAlive", true);
                        clearErrors("isSpouseAlive");
                        setValue("spouseStatus", "ALIVE");
                      }}
                      style={{
                        minWidth: "60px",
                        backgroundColor: isAlive.spouse === "yes" ? theme.palette.success.dark : theme.palette.grey[300],
                        color: isAlive.spouse === "yes" ? "white" : "inherit",
                        marginRight: "4px",
                      }}
                    />
                    <Chip
                      clickable
                      avatar={
                        <Avatar>
                          <Close fontSize="small" />
                        </Avatar>
                      }
                      disabled={onlyPreview}
                      label="No"
                      onClick={() => {
                        handleIsAlive("spouse", "no");
                        setValue("isSpouseAlive", false);
                        setValue("isSpouseMember", "");
                        setValue("isSpouseWorking", "");
                        clearErrors("isSpouseAlive");
                        setValue("spouseStatus", "EXPIRED");
                      }}
                      style={{
                        minWidth: "60px",
                        backgroundColor: isAlive.spouse === "no" ? theme.palette.error.dark : theme.palette.grey[300],
                        color: isAlive.spouse === "no" ? "white" : "inherit",
                        marginRight: "4px",
                      }}
                    />
                    <Chip
                      clickable
                      avatar={
                        <Avatar>
                          <HorizontalRuleIcon fontSize="small" />
                        </Avatar>
                      }
                      disabled={onlyPreview}
                      label="N/A"
                      onClick={() => {
                        handleIsAlive("spouse", "na");
                        setValue("isSpouseAlive", false);
                        setValue("isSpouseMember", "");
                        setValue("isSpouseWorking", "");
                        clearErrors("isSpouseAlive");
                        setValue("spouseStatus", "NA");
                      }}
                      style={{
                        minWidth: "60px",
                        backgroundColor: isAlive.spouse === "na" ? "#2196f3" : theme.palette.grey[300],
                        color: isAlive.spouse === "na" ? "white" : "inherit",
                        marginRight: "4px",
                      }}
                    />
                    {!!error && <FormHelperText error>{error?.message}</FormHelperText>}
                  </>
                )}
              />
              {isAlive.spouse === "yes" && (
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                    justifyContent: "center",
                    marginTop: "10px", // Add margin to create space
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      border: "1px solid lightgrey",
                      marginTop: "8px",
                      minWidth: "250px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="caption" align="center" color="secondary">
                      Capture Live Photo
                    </Typography>
                    <WebcamCapture
                      capturedImage={capturedImageSpouse}
                      setCapturedImage={setCapturedImageSpouse}
                      onlyPreview={onlyPreview}
                      disableBrowse
                      isValid={isValid}
                      setIsValid={setIsValid}
                    />
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      border: "1px solid lightgrey",
                      marginTop: "8px",
                      minWidth: "250px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="caption" align="center" color="secondary">
                      Upload Passport Size Photo
                    </Typography>
                    <WebcamCapture
                      capturedImage={spousePassportPhotoStr}
                      setCapturedImage={setSpousePassportPhotoStr}
                      onlyPreview={onlyPreview}
                      disableLiveCapture
                      isValid={isValid}
                      setIsValid={setIsValid}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          <div style={{ overflowX: "auto" }}>
            <table className={classes.tableDesign}>
              <tbody>
                <tr>
                  <td>Employee Name</td>
                  <td>{employeeDetails?.employeeName ?? ""}</td>
                </tr>
                <tr>
                  <td>Staff Number</td>
                  <td>{employeeDetails?.employeeId ?? ""}</td>
                </tr>
                <tr>
                  <td>BERECHS Membership Number</td>
                  <td>{employeeDetails?.berechsNo ?? ""}</td>
                </tr>
                <tr>
                  <td>Unit Name</td>
                  <td>{employeeDetails?.unitCode ?? ""}</td>
                </tr>
                <tr>
                  <td>Date of Birth</td>
                  <td>{employeeDetails?.empdob ? moment(employeeDetails?.empdob).format("DD-MM-YYYY") : ""}</td>
                </tr>
                <tr>
                  <td>Age</td>
                  <td>{employeeDetails?.age ?? ""}</td>
                </tr>
                <tr>
                  <td>Gender</td>
                  <td>{employeeDetails?.gender === "M" ? "Male" : employeeDetails?.gender === "F" ? "Female" : ""}</td>
                </tr>
                <tr>
                  <td>Category</td>
                  <td>{employeeDetails?.category}</td>
                </tr>

                <tr>
                  <td>
                    Mobile No.
                    <Required />
                  </td>
                  <td>
                    <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                      <Controller
                        control={control}
                        name="empTelecodeId"
                        render={({ fieldState: { error }, field }) => (
                          <TextField
                            {...field}
                            InputProps={{
                              classes: {
                                input: classes.input,
                              },
                            }}
                            style={{ width: "120px" }}
                            select
                            disabled={onlyPreview}
                            SelectProps={{ native: true }}
                            helperText={error?.message}
                            error={!!error}
                          >
                            {countries?.length > 0 &&
                              countries?.map(({ id, teleCode }) => (
                                <option key={id} value={id}>
                                  {teleCode}
                                </option>
                              ))}
                          </TextField>
                        )}
                      />
                      <Controller
                        control={control}
                        name="retireeMobile"
                        render={({ fieldState: { error }, field }) => (
                          <TextField
                            {...field}
                            InputProps={{
                              classes: {
                                input: classes.input,
                              },
                            }}
                            fullWidth
                            inputProps={{
                              maxLength: 12,
                              readOnly: onlyPreview,
                            }}
                            onInput={restrict.digits}
                            placeholder="Enter 10 digit mobile No"
                            helperText={error?.message}
                            error={!!error}
                          />
                        )}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Alternate Mobile No</td>
                  <td>
                    <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                      <Controller
                        control={control}
                        name="empLandlineTelecodeId"
                        render={({ fieldState: { error }, field }) => (
                          <TextField
                            {...field}
                            InputProps={{
                              classes: {
                                input: classes.input,
                              },
                            }}
                            disabled={onlyPreview}
                            style={{ width: "120px" }}
                            select
                            SelectProps={{ native: true }}
                            helperText={error?.message}
                            error={!!error}
                          >
                            {countries?.length > 0 &&
                              countries?.map(({ id, teleCode }) => (
                                <option key={id} value={id}>
                                  {teleCode}
                                </option>
                              ))}
                          </TextField>
                        )}
                      />
                      <Controller
                        control={control}
                        name="retireeLandline"
                        render={({ fieldState: { error }, field }) => (
                          <TextField
                            {...field}
                            InputProps={{
                              classes: {
                                input: classes.input,
                              },
                            }}
                            inputProps={{
                              maxLength: 12,
                              readOnly: onlyPreview,
                            }}
                            fullWidth
                            onInput={restrict.digits}
                            placeholder="Enter Alternate Mobile No."
                            helperText={error?.message}
                            error={!!error}
                          />
                        )}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    Email ID <Required />
                  </td>
                  <td>
                    <Controller
                      control={control}
                      name="retireeEMailId"
                      render={({ fieldState: { error }, field }) => (
                        <TextField
                          {...field}
                          InputProps={{
                            classes: {
                              input: classes.input,
                            },
                          }}
                          inputProps={{
                            maxLength: 100,
                            readOnly: onlyPreview,
                          }}
                          fullWidth
                          placeholder="Enter EmailId"
                          helperText={error?.message}
                          error={!!error}
                        />
                      )}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2} style={{ textAlign: "center", backgroundColor: "lightgray" }}>
                    Address as per records
                  </td>
                </tr>
                <tr>
                  <td>Address Line 1</td>
                  <td>{employeeDetails?.address}</td>
                </tr>
                <tr>
                  <td>Address Line 2</td>
                  <td>{employeeDetails?.secondAddressLine}</td>
                </tr>
                <tr>
                  <td>Country</td>
                  <td>{employeeDetails?.country}</td>
                </tr>

                <tr>
                  <td>State</td>
                  <td>{employeeDetails?.state}</td>
                </tr>

                <tr>
                  <td>District</td>
                  <td>{employeeDetails?.district}</td>
                </tr>

                <tr>
                  <td>City</td>
                  <td>{employeeDetails?.city}</td>
                </tr>

                <tr>
                  <td>Pin Code</td>
                  <td>{employeeDetails?.postalCode}</td>
                </tr>

                <tr>
                  <td colSpan={2} style={{ textAlign: "center", backgroundColor: "lightgray" }}>
                    Current Address
                  </td>
                </tr>
                <tr>
                  <td>Address Line 1</td>
                  <td>
                    <Controller
                      control={control}
                      name="addressLine1"
                      render={({ fieldState: { error }, field }) => (
                        <TextField
                          {...field}
                          InputProps={{
                            classes: {
                              input: classes.input,
                            },
                          }}
                          fullWidth
                          inputProps={{
                            maxLength: 100,
                            readOnly: onlyPreview,
                          }}
                          onInput={restrict.address}
                          placeholder="Enter Address Line 1"
                          helperText={error?.message}
                          error={!!error}
                        />
                      )}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Address Line 2</td>
                  <td>
                    <Controller
                      control={control}
                      name="addressLine2"
                      render={({ fieldState: { error }, field }) => (
                        <TextField
                          {...field}
                          InputProps={{
                            classes: {
                              input: classes.input,
                            },
                          }}
                          fullWidth
                          inputProps={{
                            maxLength: 100,
                            readOnly: onlyPreview,
                          }}
                          onInput={restrict.address}
                          placeholder="Enter Address Line 2"
                          helperText={error?.message}
                          error={!!error}
                        />
                      )}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Country</td>
                  <td>
                    <Controller
                      control={control}
                      name="countryId"
                      render={({ fieldState: { error }, field: { value, onChange } }) => (
                        <TextField
                          onChange={(e) => {
                            onChange(e);
                            setValue("stateId", "");
                            setValue("districtId", "");
                          }}
                          value={value}
                          // {...field}
                          disabled={onlyPreview}
                          InputProps={{
                            classes: {
                              input: classes.input,
                            },
                          }}
                          fullWidth
                          select
                          SelectProps={{ native: true }}
                          helperText={error?.message}
                          error={!!error}
                        >
                          <option value="">Select Country</option>
                          {countries?.length > 0 &&
                            countries?.map(({ id, name }) => (
                              <option key={id} value={id}>
                                {name}
                              </option>
                            ))}
                        </TextField>
                      )}
                    />
                  </td>
                </tr>

                <tr>
                  <td>State</td>
                  <td>
                    <Controller
                      control={control}
                      name="stateId"
                      render={({ fieldState: { error }, field: { value, onChange } }) => (
                        <TextField
                          onChange={(e) => {
                            onChange(e);
                            setValue("districtId", "");
                            // setDistricts([]);
                          }}
                          value={value}
                          InputProps={{
                            classes: {
                              input: classes.input,
                            },
                          }}
                          fullWidth
                          select
                          disabled={!watchCountry || onlyPreview}
                          SelectProps={{ native: true }}
                          helperText={error?.message}
                          error={!!error}
                        >
                          <option value="">Select State</option>
                          {states?.map(({ id, name }) => (
                            <option key={id} value={id}>
                              {name}
                            </option>
                          ))}
                        </TextField>
                      )}
                    />
                  </td>
                </tr>

                <tr>
                  <td>District</td>
                  <td>
                    <Controller
                      control={control}
                      name="districtId"
                      render={({ fieldState: { error }, field: { value, onChange } }) => (
                        <TextField
                          InputProps={{
                            classes: {
                              input: classes.input,
                            },
                          }}
                          onChange={onChange}
                          value={value}
                          fullWidth
                          select
                          disabled={!watchState || onlyPreview}
                          SelectProps={{ native: true }}
                          helperText={error?.message}
                          error={!!error}
                        >
                          <option value="">Select District</option>
                          {districts?.map(({ id, name }) => (
                            <option key={id} value={id}>
                              {name}
                            </option>
                          ))}
                        </TextField>
                      )}
                    />
                  </td>
                </tr>

                <tr>
                  <td>Pin Code</td>
                  <td>
                    <Controller
                      control={control}
                      name="pincode"
                      render={({ fieldState: { error }, field }) => (
                        <TextField
                          {...field}
                          InputProps={{
                            classes: {
                              input: classes.input,
                            },
                          }}
                          fullWidth
                          inputProps={{
                            maxLength: 8,
                            readOnly: onlyPreview,
                          }}
                          onInput={restrict.digits}
                          placeholder="Enter Current Pin Code"
                          helperText={error?.message}
                          error={!!error}
                        />
                      )}
                    />
                  </td>
                </tr>

                <>
                  <tr>
                    <td colSpan={2} style={{ textAlign: "center", backgroundColor: "lightgray" }}>
                      Spouse Detail
                    </td>
                  </tr>
                  <tr>
                    <td>Spouse Name</td>
                    <td>{employeeDetails?.spouseName ?? ""}</td>
                  </tr>
                  <tr>
                    <td>Spouse BERECHS Membership Number</td>
                    <td>{employeeDetails?.berechSpouseId ?? ""}</td>
                  </tr>
                  <tr>
                    <td>Spouse Date of Birth</td>
                    <td>{employeeDetails?.spouseDob ? moment(employeeDetails?.spouseDob).format("DD-MM-YYYY") : ""}</td>
                  </tr>
                  <tr>
                    <td>Spouse Mobile No {isAlive.spouse === "yes" && <Required />}</td>
                    <td>
                      <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                        <Controller
                          control={control}
                          name="spouseTelecodeId"
                          render={({ fieldState: { error }, field }) => (
                            <TextField
                              {...field}
                              InputProps={{
                                classes: {
                                  input: classes.input,
                                },
                              }}
                              disabled={onlyPreview || isAlive.spouse === "na"}
                              style={{ width: "120px" }}
                              select
                              SelectProps={{ native: true }}
                              helperText={error?.message}
                              error={!!error}
                            >
                              {countries?.length > 0 &&
                                countries?.map(({ id, teleCode }) => (
                                  <option key={id} value={id}>
                                    {teleCode}
                                  </option>
                                ))}
                            </TextField>
                          )}
                        />
                        <Controller
                          control={control}
                          name="spouseMobile"
                          render={({ fieldState: { error }, field }) => (
                            <TextField
                              {...field}
                              InputProps={{
                                classes: {
                                  input: classes.input,
                                },
                              }}
                              disabled={onlyPreview || isAlive.spouse === "na"}
                              fullWidth
                              inputProps={{
                                maxLength: 12,
                                readOnly: onlyPreview,
                              }}
                              onInput={restrict.digits}
                              placeholder="Enter 10 digit mobile No"
                              helperText={error?.message}
                              error={!!error}
                            />
                          )}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Spouse Alt. Mobile No </td>
                    <td>
                      <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                        <Controller
                          control={control}
                          name="spouseLandlineTelecodeId"
                          render={({ fieldState: { error }, field }) => (
                            <TextField
                              {...field}
                              InputProps={{
                                classes: {
                                  input: classes.input,
                                },
                              }}
                              disabled={onlyPreview || isAlive.spouse === "na"}
                              style={{ width: "120px" }}
                              select
                              SelectProps={{ native: true }}
                              helperText={error?.message}
                              error={!!error}
                            >
                              {countries?.length > 0 &&
                                countries?.map(({ id, teleCode }) => (
                                  <option key={id} value={id}>
                                    {teleCode}
                                  </option>
                                ))}
                            </TextField>
                          )}
                        />
                        <Controller
                          control={control}
                          name="spouseLandline"
                          render={({ fieldState: { error }, field }) => (
                            <TextField
                              {...field}
                              InputProps={{
                                classes: {
                                  input: classes.input,
                                },
                              }}
                              inputProps={{
                                maxLength: 12,
                                readOnly: onlyPreview,
                              }}
                              disabled={isAlive.spouse === "na"}
                              onInput={restrict.digits}
                              fullWidth
                              placeholder="Enter Alternate No"
                              helperText={error?.message}
                              error={!!error}
                            />
                          )}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Spouse Email ID </td>
                    <td>
                      <Controller
                        control={control}
                        name="spouseEMailId"
                        render={({ fieldState: { error }, field }) => (
                          <TextField
                            {...field}
                            InputProps={{
                              classes: {
                                input: classes.input,
                              },
                            }}
                            inputProps={{
                              maxLength: 100,
                              readOnly: onlyPreview,
                            }}
                            disabled={isAlive.spouse === "na"}
                            fullWidth
                            placeholder="Enter EmailId"
                            helperText={error?.message}
                            error={!!error}
                          />
                        )}
                      />
                    </td>
                  </tr>
                </>
              </tbody>
            </table>
          </div>
          <Grid container>
            {isAlive.spouse === "yes" && (
              <>
                <Grid item xs={12} sm={12} md={6}>
                  <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: "10px" }}>
                    <Typography variant="body1" component={"span"}>
                      Spouse working / Employed <Required /> :
                    </Typography>

                    <Controller
                      control={control}
                      name="isSpouseWorking"
                      render={({ fieldState: { error }, field }) => (
                        <FormControl disabled={onlyPreview}>
                          <RadioGroup row {...field}>
                            <FormControlLabel value="yes" control={<Radio size="small" />} label="Yes" />
                            <FormControlLabel value="no" control={<Radio size="small" />} label="No" />
                          </RadioGroup>
                          {!!error && <FormHelperText error>{error?.message}</FormHelperText>}
                        </FormControl>
                      )}
                    />
                  </div>
                  {watch("isSpouseWorking") === "yes" && (
                    <div style={{ display: "flex", alignItems: "center", gap: "10px", flexWrap: "wrap" }}>
                      <Typography variant="body1" component={"span"}>
                        Name of Employer :
                      </Typography>
                      <Controller
                        control={control}
                        name="organization"
                        render={({ fieldState: { error }, field }) => (
                          <TextField
                            {...field}
                            InputProps={{
                              classes: {
                                input: classes.input,
                              },
                            }}
                            inputProps={{
                              maxLength: 200,
                              readOnly: onlyPreview,
                            }}
                            placeholder="Enter Employer Name"
                            helperText={error?.message}
                            error={!!error}
                          />
                        )}
                      />
                    </div>
                  )}
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: "10px" }}>
                    <Typography variant="body1" component={"span"}>
                      Spouse is Member of BERECHS <Required /> :
                    </Typography>
                    <Controller
                      control={control}
                      name="isSpouseMember"
                      render={({ fieldState: { error }, field }) => (
                        <FormControl disabled={onlyPreview}>
                          <RadioGroup row {...field}>
                            <FormControlLabel value="yes" control={<Radio size="small" />} label="Yes" />
                            <FormControlLabel value="no" control={<Radio size="small" />} label="No" />
                          </RadioGroup>
                          {!!error && <FormHelperText error>{error?.message}</FormHelperText>}
                        </FormControl>
                      )}
                    />
                  </div>
                  {watch("isSpouseMember") === "yes" && watch("isSpouseWorking") === "yes" && (
                    <>
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Button
                          endIcon={<Attachment fontSize="small" />}
                          color="primary"
                          variant="contained"
                          disabled={onlyPreview}
                          onClick={() => nocFileRef.current.click()}
                          size="small"
                        >
                          Attach NOC File
                        </Button>
                        {nocFile && (
                          <Typography
                            style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
                            onClick={() => {
                              setViewNocDoc(true);
                              setPreviewDocFile(nocFile);
                            }}
                          >
                            View Noc File
                          </Typography>
                        )}
                        <input type="file" ref={nocFileRef} onChange={handleNOCUpload} style={{ display: "none" }} />
                      </div>
                      <Typography variant="body2" color="textSecondary">
                        {" "}
                        (Only ".jpg", ".jpeg", ".png", and ".pdf" files under 10MB are allowed.)
                      </Typography>
                    </>
                  )}
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={12} lg={12} style={{ marginTop: "12px" }}>
              <Controller
                control={control}
                name="isBerechMember"
                render={({ fieldState: { error }, field }) => (
                  <>
                    <FormControlLabel
                      disabled={onlyPreview}
                      control={<Checkbox {...field} checked={field.value} />}
                      label={
                        <Typography variant="body1" style={{ color: "black" }}>
                          I/We are the Members of BERECHS (BEL Retired Employees’ Contributory Health Scheme) (Revised). My/our details are
                          correct as above.
                        </Typography>
                      }
                    />
                    {!!error && <FormHelperText error>{error?.message}</FormHelperText>}
                  </>
                )}
              />

              <Controller
                control={control}
                name="isUtilizedScheme"
                render={({ fieldState: { error }, field }) => (
                  <>
                    <FormControlLabel
                      disabled={onlyPreview}
                      control={<Checkbox {...field} checked={field.value} />}
                      label={
                        <Typography variant="body1" align="justify" style={{ color: "black" }}>
                          I/We have utilized the facilities provided under the Scheme for the year
                          <span style={{ textDecoration: "underline", fontWeight: "bold", paddingInline: 8 }}>{getYear - 1}</span> to
                          <span style={{ textDecoration: "underline", fontWeight: "bold", paddingInline: 8 }}>{getYear}</span>
                          (Financial Year).
                        </Typography>
                      }
                    />
                    {!!error && <FormHelperText error>{error?.message}</FormHelperText>}
                  </>
                )}
              />
              <Controller
                control={control}
                name="isRenewMembership"
                render={({ fieldState: { error }, field }) => (
                  <>
                    <FormControlLabel
                      disabled={onlyPreview}
                      control={<Checkbox {...field} checked={field.value} />}
                      label={
                        <Typography variant="body1" style={{ color: "black" }}>
                          I / We hereby request you to renew our Membership with Insurance Company for the year
                          <span style={{ textDecoration: "underline", fontWeight: "bold", paddingInline: 8 }}>{getYear}</span>
                          to
                          <span style={{ textDecoration: "underline", fontWeight: "bold", paddingInline: 8 }}>{getYear + 1}</span>{" "}
                          (Financial Year).
                        </Typography>
                      }
                    />
                    {!!error && <FormHelperText error>{error?.message}</FormHelperText>}
                  </>
                )}
              />
              {(isAlive.emp === "no" || isAlive.spouse === "no") && (
                <>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Controller
                      control={control}
                      name="deathDeclaration"
                      render={({ fieldState: { error }, field }) => (
                        <FormControlLabel disabled control={<Checkbox {...field} checked={true} />} style={{ marginRight: 0 }} />
                      )}
                    />
                    <Typography variant="body1" style={{ color: "black" }}>
                      I hereby declare my{" "}
                      <span style={{ textDecoration: "underline", fontWeight: "bold", paddingInline: 8 }}>
                        husband/wife
                        {/* {employeeDetails?.gender !== "M"  ? "Husband" : "Wife"} */}
                      </span>
                      has expired on
                      <span style={{ paddingInline: 8 }}>
                        <Controller
                          control={control}
                          name="deathDate"
                          render={({ fieldState: { error }, field }) => (
                            <TextField
                              style={{ maxWidth: "150px", width: "100%" }}
                              type="date"
                              {...field}
                              InputProps={{
                                classes: {
                                  input: classes.input,
                                },
                              }}
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                              inputProps={{
                                readOnly: onlyPreview,
                                max: moment(new Date()).format("YYYY-MM-DD"),
                              }}
                              helperText={error?.message}
                              error={!!error}
                            />
                          )}
                        />
                      </span>
                      <span>. I request for deleting his/her membership under the scheme</span>
                    </Typography>
                  </div>
                  <input type="file" ref={deathCertificateRef} onChange={handleUploadDeathCertificate} style={{ display: "none" }} />
                  <div className="no-print" style={{ display: "flex", gap: "20px", alignItems: "center", marginBlock: "8px" }}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        deathCertificateRef.current.click();
                      }}
                      disabled={onlyPreview}
                    >
                      Attach death Certificate
                    </Button>
                    {deathCertificateFile && (
                      <Typography
                        className="no-print"
                        style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
                        onClick={() => {
                          setViewNocDoc(true);
                          setPreviewDocFile(deathCertificateFile);
                        }}
                      >
                        View Death Certificate
                      </Typography>
                    )}
                  </div>
                  <Typography variant="body2" color="textSecondary" className="no-print">
                    {" "}
                    (Only ".jpg", ".jpeg", ".png", and ".pdf" files under 10MB are allowed.)
                  </Typography>
                </>
              )}
              {saving && (
                <p>
                  Saving Form Please wait... <CircularProgress size={20} />
                </p>
              )}
              {!onlyPreview && (
                <div className="no-print" style={{ display: "flex", justifyContent: "center", gap: 6, alignItems: "center" }}>
                  <Button
                    disabled={
                      disableDraftButton || disableButton || saving || loading || renewalStatus === 1 || renewalStatus === 3 || !isValid
                    }
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleSubmit((data) => handleFormSubmit(data, "draft"))}
                  >
                    {isEditing ? "Update as Draft" : "Save as Draft"}
                  </Button>
                  <Button
                    disabled={disableButton || saving || loading || renewalStatus === 1 || renewalStatus === 3 || !isValid}
                    type="submit"
                    color="secondary"
                    variant="contained"
                    size="small"
                    onClick={handleSubmit((data) => handleFormSubmit(data, "submit"))}
                  >
                    Submit
                  </Button>

                  <Button
                    disabled={disableButton || saving || loading || renewalStatus === 1 || renewalStatus === 3}
                    variant="contained"
                    size="small"
                    onClick={handleReset}
                  >
                    Reset
                  </Button>
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </form>
    </>
  );
}

function WebcamCapture({ capturedImage, setCapturedImage, onlyPreview, disableBrowse, disableLiveCapture, isValid, setIsValid }) {
  const classes = useStylesRenewalForm();
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const fileInputRef = useRef(null);
  const [isWebcamActive, setIsWebcamActive] = useState(false);
  const streamRef = useRef(null);
  const startWebcam = async () => {
    setCapturedImage(null);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: "user",
        },
        audio: false,
      });
      streamRef.current = stream;
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        setIsWebcamActive(true);
      }
    } catch (err) {
      toast.error("No Web Camera found");
      console.error("Error accessing the webcam:", err);
    }
  };
  const [validationMessage, setValidationMessage] = useState("");
  const stopWebcam = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
      setIsWebcamActive(false);
    }
  };

  const capture = useCallback(() => {
    const video = videoRef.current;
    const canvas = canvasRef.current;

    if (video && canvas) {
      const context = canvas.getContext("2d");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      const imageSrc = canvas.toDataURL("image/jpeg");

      setCapturedImage(imageSrc);

      // Stop webcam after capturing the image
      stopWebcam();
    }
  }, []);

  // File validation for image upload
  const handleFileUpload = (event) => {
    const validFileTypes = ["jpg", "png", "jpeg"];
    const file = event.target.files[0];
    console.log("maxSize", file);
    // Reset previous validation message
    setValidationMessage("");
    setIsValid(true);
    if (file) {
      console.log("maxSize");
      // Validate file size (Example: limit to 60kb)
      const maxSize = 0.06 * 1024 * 1024; // 60kb
      if (file.size > maxSize) {
        setIsValid(false);
        setValidationMessage("File is too large. Please upload an image smaller than 60kb.");
        return;
      }

      const checkIsValidFile = file.name.split(".").pop();
      if (!validFileTypes.includes(checkIsValidFile.toLowerCase())) {
        setIsValid(false);
        setValidationMessage("Only jpg, png, jpeg file type is allowed");
        return;
      }

      // If validation passes, read the file
      const reader = new FileReader();
      reader.onloadend = () => {
        setCapturedImage(reader.result);
        stopWebcam();
        setIsWebcamActive(false);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className={classes.root}>
      <Paper elevation={3} className={classes.paper}>
        {capturedImage && !isWebcamActive ? (
          <img src={capturedImage} alt="Captured" height={"100%"} />
        ) : (
          <Typography hidden={isWebcamActive} variant="caption" color="primary">
            {disableBrowse ? "Capture" : "Upload"} Profile Photo{" "}
            {!disableBrowse ? `(only ".jpg", ".jpeg" and ".png" files allowed and should be <=60kb)` : ""}
          </Typography>
        )}

        <video ref={videoRef} autoPlay height={"100%"} hidden={!isWebcamActive} />
      </Paper>
      <canvas ref={canvasRef} style={{ display: "none" }} />
      {!onlyPreview && (
        <>
          <div className={classes.buttonGroup}>
            {!disableLiveCapture && (
              <>
                <Button
                  classes={{ root: classes.buttonRoot }}
                  startIcon={<PhotoCamera />}
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={startWebcam}
                  disabled={isWebcamActive}
                >
                  Start
                </Button>

                <Button
                  classes={{ root: classes.buttonRoot }}
                  startIcon={<Camera />}
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={capture}
                  disabled={!isWebcamActive}
                >
                  Capture
                </Button>
              </>
            )}
            {!disableBrowse && (
              <Button
                classes={{ root: classes.buttonRoot }}
                startIcon={<InsertPhoto />}
                size="small"
                variant="outlined"
                color="secondary"
                onClick={() => fileInputRef.current.click()}
              >
                Browse
              </Button>
            )}
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileUpload}
              accept="image/jpg,image/png,image/jpeg"
              style={{ display: "none" }}
            />
          </div>
          {validationMessage && (
            <Typography variant="caption" style={{ color: "red" }}>
              {validationMessage}
            </Typography>
          )}
        </>
      )}
    </div>
  );
}

function PreViewNocDoc({ data, onClose }) {
  if (!data) {
    return <>Document Not Found</>;
  }
  const dataType = data.split(";");
  const isPdf = dataType[0] === "data:application/pdf";

  return (
    <Dialog open maxWidth="lg" fullWidth>
      <DialogTitle onClose={onClose}>Preview DOC</DialogTitle>
      <DialogContent>
        {isPdf ? (
          <object data={data} aria-labelledby="none" style={{ width: "100%", height: "500px" }}></object>
        ) : (
          <img src={data} alt="doc-images" />
        )}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" size="small" onClick={onClose} variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
